import React from "react";

const ImageCarousel = () => {
  const images = [
    "/images/villapic1.jpg",
    "/images/villapic2.jpg",
    "/images/villapic3.jpg",
    "/images/villapic4.jpg",
    "/images/carousel-img01.jpg",
    "/images/carousel-img02.jpg",
    "/images/carousel-img03.jpg",
    "/images/carousel-img04.jpg",
    "/images/carousel-img05.jpg",
    "/images/carousel-img06.jpg",
    "/images/carousel-img07.jpg",
  ];

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <img key={index} src={image} alt={`Gallery ${index + 1}`} />
      ))}
    </div>
  );
};

export default ImageCarousel;
