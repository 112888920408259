import React from 'react';
import './WhatsAppButton.css'; // Create this CSS file for styling

function WhatsAppButton() {
  const phoneNumber = +919409995101; // Replace with your actual phone number

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp"></i> Chat on WhatsApp
    </a>
  );
}

export default WhatsAppButton;
