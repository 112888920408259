import React from 'react';

const RoomCard = ({ imageSrc, altText, roomType, whatsappLink }) => (
  <div className="flex flex-col items-center ">
    <img src={imageSrc} alt={altText} className="w-full h-81 object-cover mb-4" />
    <a href={whatsappLink} className="bg-green-900 text-white py-2 px-4 rounded-full flex items-center mb-2">
      <i className="fab fa-whatsapp mr-2"></i>
      {roomType}
    </a>
    
  </div>
);

export default RoomCard;