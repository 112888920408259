import React, { useRef, useState } from "react";
import { gsap } from "gsap";
import "./App.css";
import ImageCarousel from "./ImageCarousel";
import minilogo from "../src/Asset/images/minilogo.png"
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { useGSAP } from "@gsap/react";
import img1 from "../src/Asset/images/home-image.jpg";
import aboutus_image from "../src/Asset/images/hotelpic-removebg-preview.png";

import logo from "../src/Asset/images/logobgr.png.png";
import galry from "../src/Asset/images/IMG-20240830-WA0022.jpg";
import WhatsAppButton from "./WhatsAppButton";
import App3 from "./App3";
import FloatingContactoIcons from "./FloatingContactoIcons";

const App = () => {
  const [isOpen, setIsOpen] = useState(false); // Use state to handle navbar toggle
  const sectionsRef = useRef([]);
  const navbarRef = useRef(null);

  useGSAP(() => {
    gsap.from(navbarRef.current, { x: -50, opacity: 0, duration: 1 });
    sectionsRef.current.forEach((section, index) => {
      gsap.from(section, {
        y: 50,
        opacity: 0,
        duration: 1,
        delay: index * 0.3,
      });
      gsap.from(".dining", {
        y: 50,
        opacity: 0,
        duration: 1,
        delay: 0.5,
      });
      gsap.from(".dining-image", {
        x: -50,
        opacity: 0,
        duration: 1,
        delay: 1,
      });
      gsap.from(".dining-text", {
        x: 50,
        opacity: 0,
        duration: 1,
        delay: 1.5,
      });
    });
  }, []);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useGSAP(() => {
    const handleWheel = (event) => {
      if (!isAnimating) {
        setIsAnimating(true);
        setScrollDirection(event.deltaY > 0 ? "down" : "up");
      }
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [isAnimating]);

  useGSAP(() => {
    if (scrollDirection === "down") {
      gsap.to(".maro", {
        x: "-200%",
        repeat: -1,
        duration: 4,
        ease: "none",
        onComplete: () => setIsAnimating(false),
      });
      gsap.to(".maro img", {
        rotation: 180,
        duration: 4,
      });
    } else if (scrollDirection === "up") {
      gsap.to(".maro", {
        x: "0%",
        repeat: -1,
        duration: 4,
        ease: "none",
        onComplete: () => setIsAnimating(false),
      });
      gsap.to(".maro img", {
        rotation: 0,
        duration: 4,
      });
    }
  }, [scrollDirection]);

  const addToRefs = (el) => {
    if (el && !sectionsRef.current.includes(el)) {
      sectionsRef.current.push(el);
    }
  };

  return (
    <div className="app min-h-screen overflow-x-hidden">
      <nav className="navbar" ref={navbarRef}>
        <div className="navbar-brand">
          <img src={logo} alt="" className="logo-img" />
        </div>

        <div className="navbar-toggle" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
          <li>
            <a href="#about">About Us</a>
          </li>
          <li>
            <a href="#gallery">Gallery</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#app3">Rooms Available</a>
          </li>
        </ul>
      </nav>
      <header className="header">
        <h1>Welcome To Hotel Balaji Villa</h1>
      </header>
      <section className="about" id="about">
        <h2>About Us</h2>
        <hr/>
        <div className="a-sec">
          <div>
            <img src={aboutus_image} alt="About Us" className="about-image" />
          </div>
          <div className="abt">
            <p>
              Welcome to Hotel Balaji Villa, where we offer a unique blend of
              comfort and culinary excellence. Our establishment is designed to
              provide a cozy atmosphere, making it a perfect getaway for
              families, couples, and solo travelers alike.
            </p>
            <br />
            <p>
              Our restaurant serves a variety of dishes, from traditional Indian
              cuisine to international favorites, all prepared with the freshest
              ingredients. Our chefs are dedicated to creating memorable dining
              experiences that will leave you coming back for more.
            </p>
            <br />
            <p>
              At our guest house, we prioritize your comfort. Each room is
              thoughtfully designed with modern amenities, ensuring a relaxing
              stay. Whether you're here for a short visit or an extended stay,
              we strive to make your experience unforgettable.
            </p>
            <br />
            <p>
              Join us for a meal, or stay with us for a while. We look forward
              to welcoming you to Hotel Balaji Villa!
            </p>
            <br />
          </div>
        </div>
      </section>
      <div id="app3">
        <section className="welcome">
          <div className="App">
            <div id="page2">
              <div id="move" className="move">
                {[1, 2, 3, 4].map((_, index) => (
                  <div key={index} className="maro">
                    <div>
                      <h1>Rooms Availabel</h1>{" "}
                    </div>
                    <div>
                      {" "}
                      <img src={minilogo} alt="Hotel Logo" />{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <App3 />
      </div>

      <section className="gallery" id="gallery" ref={addToRefs}>
        <h2>Gallery</h2>
        <ImageCarousel />
      </section>

      <section className="services" id="services">
        <div>
          <h2>We Serve Our Best</h2>
        </div>
        <div className="s-sec">
          <div className="s-sec2">
            <img src={galry} alt="Services" className="services-image" />
          </div>
          <div className="s-sec1">
            <ul>
              <li>Breakfast</li>
              <li>Lunch & Dinner</li>
              <li>Room-Attractive Studios</li>
              <li>Dinner</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="dining" id="dining">
        <h2>Dining at Hotel Balaji Villa</h2>
        <hr />
        <div className="dining-content">
          <div className="dining-image">
            <img src={img1} alt="Dining" />
          </div>
          <div className="dining-text">
            <p>
              Our restaurant serves a variety of dishes, from traditional Indian
              cuisine to international favorites, all prepared with the freshest
              ingredients.
            </p>
            <p>
              Our chefs are dedicated to creating memorable dining experiences
              that will leave you coming back for more.
            </p>
            <ul>
              <li>Breakfast: 7:00 am - 10:00 am</li>
              <li>Lunch: 12:00 pm - 3:00 pm</li>
              <li>Dinner: 7:00 pm - 10:00 pm</li>
            </ul>
          </div>
        </div>
      </section>

      <WhatsAppButton phoneNumber="+919409995101" />
      <footer className="footer">
        <h2>Contact With Us</h2>
        <hr />
        <br />
        <div className="cont-info">
          <div>
            <img src={logo} alt="" />
          </div>
          <div>
            <a>
              <FaMapMarkerAlt size={24} /> Work Address: Apna House, 50/W, Hari Nagar
              Cross Rd, Near Bank Of Baroda, Gotri, Vadodara, Gujarat 390015.
            </a>
            <a href="tel:8141377359">
              <FaPhone size={24}/> Contact: +91-8141377359
            </a>
            <a href="mailto:hotelbalajivilla@gmail.com">
              <FaEnvelope size={24}/> Email: hotelbalajivilla4455@gmail.com
            </a>
          </div>
        </div>
        <FloatingContactoIcons/>
      </footer>
    </div>
  );
};

export default App;
