import React from 'react';
import RoomCard from './RoomCard';
import card_01 from './Asset/images/room-card01.jpg';
import card_02 from './Asset/images/room-card02.jpg'

const App3 = () => (
  <div className="container mx-auto p-4">
    <div className=" grid grid-cols-1 md:grid-cols-2 gap-4">
      <RoomCard 
        imageSrc={card_01}
        altText="Standard Family AC Room with a mirror, wardrobe, and air conditioner" 
        roomType="Book Standard Family Ac Room" 
        whatsappLink="https://wa.me/+918141377359" 
      />
      <RoomCard 
        imageSrc= {card_02}
        altText="Semi Deluxe Family Room with a door, mirror, and tiled floor" 
        roomType="Book Semi Deluxe Family Room" 
        whatsappLink="https://wa.me/+918141377359" 
      />
    
    </div>
  </div>
);

export default App3;